import * as React from "react"
import { Link } from "gatsby"
import FrontmatterPageContext from "~context/FakePageContext"
import { H1, Grid, Text, Box } from "@fastly/consistently"

import { main, mainArea } from "~vanilla/homepage.css"

import { theRowIcon } from "~vanilla/homepage.css"

import { SeoHead } from "~components/Head/SeoHead"
import { FavHead } from "~components/Head/FavHead"
import LayoutCore from "~templates/layout-core"

import frontpageBoxes from "~data/frontpage_boxes.yml"
import { vars } from "../vanilla/theme.css"

// these came from the /index.md page
let frontmatter = {
  // layout: "default_full",
  section: "front",
  section_safe: "front",
  title: "Fastly Documentation",
  lang: "en",
}

const FrontIndexPage = () => {
  let boxes = frontpageBoxes.map((box) => {
    return (
      <Box key={box.svg}>
        <Link to={box.href}>
          <img className={theRowIcon} alt="" role="img" src={`/assets/images/front/${box.svg}.svg`} />
          <br />
          <Text as="header" variant="xl">
            {box.title}
          </Text>
        </Link>
        <Text as="div" variant="md" color={vars.color.text.body}>
          {box?.text}
        </Text>
      </Box>
    )
  })

  return (
    <>
      <FrontmatterPageContext.Provider value={frontmatter}>
        <LayoutCore>
          <div className={`${mainArea} grid-main`}>
            <main className={`${main} main-content section-${frontmatter.section_safe}`} role="main">
              <div className="content-content">
                <Box p="20px" borderRadius={vars.radius.md} bg="var(--grey-20)" color={vars.color.text.body} marginBottom={vars.space.md}>
                  <H1 m="0">
                    <img
                      style={{ verticalAlign: "middle", width: "8rem", height: "8rem" }}
                      src="/assets/images/front/welcome.svg"
                      alt="a graduation cap"
                      role="presentation"
                    />{" "}
                    Welcome!
                  </H1>
                  <p>
                    The Fastly Documentation Site presents a series of articles and tutorials to help you get the most
                    out of using Fastly for your site, service, or application.
                  </p>
                </Box>
                <Grid sm={2} md={3} lg={5} mb={vars.space.lg}>
                  {boxes}
                </Grid>
                Need more info about our security safeguards? Check out our{" "}
                <Link to="/en/guides/security-measures">security measures</Link> guide.
              </div>
            </main>
          </div>
        </LayoutCore>
      </FrontmatterPageContext.Provider>
    </>
  )
}

export default FrontIndexPage

export const Head = (props) => {
  return <>
    <title>{frontmatter.title}</title>
    <SeoHead frontmatter={frontmatter} {...props} />
    <FavHead />
  </>
}
